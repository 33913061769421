@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.banner_wrapper {
  width: 100%;
  background: transparent;
  background-size: cover;
  background-color: #fff;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .banner_custom {
    > * {
      overflow: visible;
    }
  }
  .banner_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: auto 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      color: #727171;
    }
    &.banner_arrow_left {
      left: -18px;
    }
    &.banner_arrow_right {
      right: -18px;
    }
  }
  .textContent {
    width: 100%;
    max-width: 420px;
    display: flex;
    align-items: center;
    z-index: 1;
    .textContentInner {
      width: 100%;
      height: 100%;
      padding-left: 83px;
      z-index: 9;
      .banner_title {
        width: 420px;
        height: 94px;
        font-size: 24px;
        text-align: left;
        color: #ffffff;
        line-height: 32px;
        overflow-wrap: break-word;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .banner_desc {
        width: 420px;
        height: 96px;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
        margin-top: 12px;
        margin-bottom: 16px;
        @include text-overflow(4);
      }
    }
  }
  .banner_title_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
        60deg,
        transparent 0%,
        transparent 12%,
        rgba(0, 173, 239, 0.4) 12%,
        rgba(0, 173, 239, 0.4) 32%,
        transparent 32%
      ),
      linear-gradient(
        60deg,
        transparent 0%,
        transparent 12%,
        rgba(0, 173, 239, 0.5) 12%,
        rgba(0, 173, 239, 0.5) 24%,
        transparent 24%
      ),
      linear-gradient(120deg, rgba(0, 173, 239, 0.2) 45%, transparent 45%),
      linear-gradient(120deg, rgba(0, 173, 239, 0.2) 30%, transparent 30%);
  }
  [class="carousel carousel-slider"] {
    position: relative;
  }
  [class="slider-wrapper axis-horizontal"] {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
    ul {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      li {
        min-width: 100%;
        margin: 0;
        position: relative;
        text-align: center;
        background: #000;
      }
    }
  }
}
