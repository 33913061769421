@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.button{
  border-radius: 4px;
  line-height: 16px;
}
.mui-btn-contained-white {
  width: 328px;
  height: 40px;
  color: #00adef;
  background-color: #ffffff !important;
  &:hover {
    background-color: rgba(0, 173, 239, 0.06) !important;
  }
}

.mui-btn-contained-label-blue {
  color: #00adef;
}

.mui-btn-contained-label-gray {
  color: #727171;
}
.mui-btn-contained-gray {
  width: 328px;
  height: 40px;
  color: #00adef;
  background-color: $gray15 !important;
  &:hover {
    background-color: rgba(0, 173, 239, 0.06) !important;
  }
}

.mui-btn-contained-label-white {
  color: #ffffff;
}

.mui-btn-contained-blue {
  width: 328px;
  height: 40px;
  background-color: #00adef !important;
  &:hover {
    opacity: 0.8;
  }
}
