@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@mixin cardTemplate() {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.skeleton {
  span {
    background-color: $gray100;
  }
}

.category {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 8px;
  span {
    border-radius: 4px;
    height: 56px;
  }
}

.cardSkeleton {
  @include cardTemplate();
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  .img {
    width: 100%;
    height: 158px;
  }
  .contentInner {
    min-height: 145px;
    padding: 14px 14px 8px;
    > span {
      display: inline-block;
    }
    .title {
      width: 100%;
    }
    .subTitle {
      width: 100px;
    }
  }
}

.rowSkeleton {
  background-color: $gray100;
  min-height: 104px;
  width: 100%;
  margin-bottom: 8px;
}

.HorizontalSkeleton {
  @include cardTemplate();
  height: 190px;
  .horizontalSkeletonInner {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px 20px;
    .img {
      width: 280px;
      height: 158px;
      border-radius: 6px;
    }
    .contentInner {
      width: calc(100% - 304px);
      height: 100%;
      margin-left: 24px;
      > span {
        margin-bottom: 6px;
        border-radius: 6px;
        &.subTitle {
          width: 300px;
        }
      }
    }
  }
}
