@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.wrapper {
  margin-top: 64px;
  .container {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
  }
  button {
    display: flex;
    margin: auto;
    margin-top: 34px;
    padding: 10px 59px;
  }
  .tipOne {
    text-align: center;
    font-size: $f16;
    line-height: $lh20;
    margin-top: 16px;
    font-weight: $fw500;
  }
  .tipTwo {
    text-align: center;
    font-size: $f14;
    line-height: $lh22;
    margin-top: 16px;
    font-weight: $fw400;
  }
}