@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.wrapper {
  width: 100%;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gray15;
  border-radius: 6px;
  margin-top: 34px;
  .content {
    display: flex;
    justify-self: center;
    height: 100%;
    width: 405px;
    flex-direction: column;
    row-gap: 16px;
    margin-left: 40px;
    .title {
      font-size: $f20;
      font-weight: $fw500;
      line-height: $lh24;
      color: $gray500;
    }
    .subTitle {
      font-size: $f14;
      line-height: $lh22;
      color: $gray300;
    }
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      height: 36px;
      column-gap: 16px;
      .actionButton {
        width: 112px;
        height: 36px;
      }
    }
  }
}
