@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.cardWrapperInner {
  display: flex;
  // grid-template-columns: repeat(5, 1fr);
  column-gap: 8px;
  .categories {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    .categoriesInner {
      width: 100%;
      height: 100%;
      .categoriesImg {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 56px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
        .text {
          padding: 8px;
          font-size: $f14;
          color: #fff;
          z-index: 1;
          text-align: center;
        }
      }
    }
  }
}
